import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from './ErrorMessage';
import logo from '../../images/logo.jpeg';
import './finalPage.css';

const FinalPage = ({setIsNewTagsVisible}) => {
  const [subscribeEmail,setSubscribeEmail]=useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [grantAccess, setGrantAccess] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [error,setError]=useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  useEffect(() => {
    if(localStorage.getItem('isLoggedIn') && localStorage.getItem('subscribed')){
      setSubscribeEmail(localStorage.getItem('loggedInEmail'));
      setIsDisabled(true);
    }
    if(localStorage.getItem('loggedInEmailGoogle') && localStorage.getItem('subscribedByGoogle')){
      setSubscribeEmail(localStorage.getItem('loggedInEmailGoogle'));
      setIsDisabled(true);
    }
    if(localStorage.getItem('isLoggedIn') || localStorage.getItem('loggedInEmailGoogle')){
      setGrantAccess(true);
    }
  }, []);

  const handleSubmitForSubscribe = async (event) =>{
    event.preventDefault();
    if( localStorage.getItem('isLoggedIn') || localStorage.getItem('isLoggedInByGoogle')){
      const email=localStorage.getItem('isLoggedIn')?localStorage.getItem('loggedInEmail'):localStorage.getItem('loggedInEmailGoogle');
      if(subscribeEmail===email){
        try{
          const response = await fetch('/api/subscribeEmail', {
            method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({subscribeEmail})
            });
            const data = await response.json();
            if(data.message==="Subscribed Successfully to our newsletter"){
              setIsDisabled(true);
            }
        } catch (error) {}
      }else{
        setError("Email doesn't match with logged in mail");
      }
    }else{
        setError('Please log in first');
        setIsNewTagsVisible(true);
    }
  }

  const getMarginLeft = () => {
    if (isDisabled) {
      return screenWidth <= 390 ? screenWidth<=330?'-108px': '-118px' : '-128px';
    } else {
      return screenWidth <= 390 ? screenWidth<=330?'-98px': '-108px' : '-118px';
    }
  };

  const navigateToTermsAndCondition=(event)=>{
    event.preventDefault();
    navigate({
        pathname: "/terms_and_condition",
    });
  }

  const navigateToPrivacyPolicy=(event)=>{
    event.preventDefault();
    navigate({
        pathname: "/Privacy_Policy",
    });
  }

  const navigateToDisclaimer=(event)=>{
    event.preventDefault();
    navigate({
        pathname: "/Disclaimer",
    });
  }

  const navigateToContactUs=(event)=>{
    event.preventDefault();
    navigate({
        pathname: "/ContactUs",
    });
  }

  const navigateToRefundAndCancellationPolicy=(event)=>{
    event.preventDefault();
    navigate({
        pathname: "/RefundAndCacellationPolicy",
    });
  }
  
  const handleCloseError = () => {
    setError(null);
  };

  return (
    <section className="endingPage">
      <div className='ending'>
      <h2 className='follow-for-mobile-view'>
      <img src={logo} alt={"ADVENTROZ"}/>
        ADVENTROZ</h2>
        <div className='subscribeContent'>
          <h2 className='follow'>
          <img src={logo} alt={"ADVENTROZ"}/>
            ADVENTROZ</h2>
          <div className='textForNewsletter'>
            Sign up for our newsletter to stay updated on 
            new features and releases.
          </div>
          <div className='subscribeForm'>
              <form onSubmit={handleSubmitForSubscribe}>
                <input type="email" value={subscribeEmail} disabled={isDisabled} onChange={(e)=>setSubscribeEmail(e.target.value)}  onPaste={(e)=>setSubscribeEmail(e.clipboardData.getData('text'))} name="email" label="Enter your email : " placeholder='Enter your email : ' required/>
                <input type="submit" disabled={isDisabled}  value={isDisabled ? "Subscribed" : "Subscribe"} style={{ backgroundColor: isDisabled ? 'grey' : 'black' , marginLeft: getMarginLeft()}}/>
              </form>
              <p>By subscribing, you agree to our Privacy Policy and consent to receive updates from our company.</p>
          </div>
        </div>
        <div className='links'>
          <div className='linkforterms'>
            <p className='linksfortermsHeading'>Links</p>
            <p className='disclaimerLink' onClick={navigateToDisclaimer}>Disclaimer</p>
              <p className='termsAndConditionLink' onClick={navigateToTermsAndCondition}>Terms & Condition</p>
              <p className='privacyPolicyLink' onClick={navigateToPrivacyPolicy}>Privacy Policy</p>
              <p className='refundAndCancellationPolicy' onClick={navigateToRefundAndCancellationPolicy}>Refund And Cancellation Policy</p>
              <p className='contactUsLink' onClick={navigateToContactUs}>Contact Us</p>
          </div>
          <div className='followUs'>
            <p className='followUsHeading'>Follow Us</p>
            <p><a href='https://www.facebook.com/profile.php?id=61563328013355&mibextid=ZbWKwL'>Facebook</a></p>
            <p><a href='https://www.instagram.com/adventroz?igsh=bmx2ZHllZ2d6eXQ3'>Instagram</a></p>
            <p><a href='https://x.com/adventroz?t=uNA2OrDz-aVTcMi0nZURAg&s=08'>Twitter</a></p>
          </div>
        </div>
      </div>   
      <div className='dividerLine'></div>
      <div className='copyright'><span> &copy; </span>Copyright 2024 All right reserved-ADVENTROZ</div>
      {error && (<ErrorMessage message={error} onClose={handleCloseError}/>)}
    </section>
  );
};

export default FinalPage;